import { Model, Attribute, HasMany, RelationHandling } from './decorators';
import { BaseApiModel } from './base';
import { TopicType, WebhookEventType, WebhookType } from '@parashift/shared/types';
import { WebhookEvent } from './webhook-event.model';
import { Topic } from './topic.model';

export interface Headers {
  [name: string]: string;
}

export interface ConvertedHeader {
  name: string;
  value: string;
}

export interface WebhookPlainModel {
  id: string;
  created_at: string;
  headers: ConvertedHeader[];
  name: string;
  tenant_id: string;
  updated_at: string;
  url: string;

  events: (WebhookEvent['plainModel'] | Partial<WebhookEvent['plainModel']>)[];
  topics: (Topic['plainModel'] | Partial<Topic['plainModel']>)[];
}

@Model({ type: WebhookType })
export class Webhook extends BaseApiModel<WebhookPlainModel> {

  @Attribute()
  created_at: string;

  @Attribute()
  headers: Headers;

  @Attribute()
  name: string;

  @Attribute()
  tenant_id: string;

  @Attribute()
  updated_at: string;

  @Attribute()
  url: string;

  // Includes / Relations

  @HasMany({ class: WebhookEventType, readonly: true })
  events: (WebhookEvent | Partial<WebhookEvent>)[];

  @RelationHandling('disassociate')
  @HasMany({ class: TopicType, readonly: false })
  topics: (Topic | Partial<Topic>)[];

  get plainModel(): WebhookPlainModel {
    const model = this.toHash() as WebhookPlainModel;
    model.headers = this.getHeaders(this.headers);
    return model as Webhook['plainModel'];
  }

  set plainModel(model) {
    this.customUpdates = {
      headers: setHeaders,
    };

    this.setPlainModel(model);
  }

  getHeaders(headers: Headers): ConvertedHeader[] {
    headers = headers || {};
    const convertedHeaders: ConvertedHeader[] = [];

    for (const key in headers) {
      if (Object.prototype.hasOwnProperty.call(headers, key)) {
        convertedHeaders.push({ name: key, value: headers[key]});
      }
    }

    return convertedHeaders;
  }
}

function setHeaders(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string) {
  const convertedHeaders: { [attr: string]: string } = {};

  updatedPlainModel[key].forEach((header: ConvertedHeader) => {
    convertedHeaders[header.name] = header.value;
  });

  existingModel[key] = convertedHeaders;
}
